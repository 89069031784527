<template>
  <v-card elevation="0">
    <v-card-text>変更する前にバックアップをお勧めします</v-card-text>
    <v-card-text>
      <v-form ref="form">
        <validation-provider v-slot="{ errors }" name="設定内容" rules="required|max:1000">
          <v-textarea
            rows="20"
            outlined
            v-model="editor.setting"
            label="*設定内容"
            :rules="[(v) => !!v || '設定内容 は必須です']"
            counter
            :maxlength="1000"
            :error-messages="errors"
            clearable
            required
          />
        </validation-provider>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" class="mr-4" min-width="150" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onSubmitClicked">
        送信
        <v-icon>mdi-email-send</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  data: () => ({
    initializing: true,

    editor: {
      loading: false,

      setting: null,
    },
  }),

  methods: {
    /**
     * 初期化時
     */
    onLoaded() {
      //
      //
      this.get('setting', {})
        .then((success) => {
          //
          let data = success.data;
          //
          this.editor.setting = data.setting;
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
    /**
     * フォームクリアイベント
     */
    onClearClicked() {
      this.form.reset();
    },
    /**
     * フォーム決定イベント
     */
    async onSubmitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      this.post('setting', this.editor)
        .then((success) => this.showSuccessSnackbar(success))
        .catch((error) => this.showErrorSnackbar(error));
    },
  },
  watch: {
    /** フォーム変更監視 */
    editor: {
      handler: function () {
        this.setFormChanged(true);
      },
      deep: true,
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>